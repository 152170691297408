<template>
  <div class="keepAdmin">
        <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
          <template v-if="ishow">
            <van-empty
              class="custom-image"
              :image="require('@/assets/images/pic_no information@2x.png')"
              description="暂无数据!"
            />
          </template>
            <van-list
                 v-model="loading"
                 :finished="finished"
                 finished-text="没有更多了"
                 @load="onLoad"
                 v-if="!ishow"
            >

                <div class="data" v-for="(item) in list" :key="item.openId" @click="TOstockManage(item)">
                     <div class="startTime">{{item.created | formatDate_ | formatTime}}</div>
                     <div class="weight">{{item.weight.toFixed(2)}}g</div>
                     <div class="realTlmeMOney" :style="{color:item.overPercent?'#dc001a':'#1a1a1a'}">{{item.mortgageStatus =="CLOSE"?'--':item.mortgageStatus=="WAIT_CONFIRM"?'--':item.realTimeMoney.toFixed(2)+'元'}}</div>
                     <div class="state" :style="{color:item.mortgageStatus =='MORTGAGING'?'#F52621':item.mortgageStatus == 'CLOSE'?'#00AD8C':'#FF7A00'}">{{item.mortgageStatus == "CLOSE"?'已结算':item.mortgageStatus == "MORTGAGING"?'存料中':'申请中'}}</div>
               </div>
            </van-list>
        </van-pull-refresh>
  </div>
</template>

<script>
import *as api from'@/utils/api'
export default {
     data() {
    return {
        // 存料数据
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      page:0,
      size:8,
      ishow: true
    };
  },
  methods: {
    //   列表点击事件
    TOstockManage(item){
            this.$router.push({
            name:"stockManage",
            query:{openId:item.openId}
        })
    },
    async onLoad() {
         console.log(1111)
         try {
           let res = await api.getStockList({
             page: this.page,
             size: this.size,
           })
           // 如果加载完数据了 last为true
           // 把上一段 number赋值给page
           this.page = res.number + 1

           // 把数组里的每一项一一铺开添加
           this.list.push(...res.content)
           // console.log(this.list);
           // 本次加载完毕，就应该把loading改成false，代表本次加载完了，让加载中状态消失
           // 这样才可以继续下次加载
           this.loading = false;
           if (res.last == true) {
             this.finished = true;
           }
         } catch {
           this.ishow = true
         }
    },
   async onRefresh() {
                 //请求最新的数据
     try {
       let res = await api.getStockList({
         page: 0,
         size: this.size,
       })
       this.page = res.number + 1
       this.list = res.content
       console.log(this.list);
     } catch {
       this.ishow = true
     } finally {
       if (this.list.length === 0) {
         this.ishow = true
         this.refreshing = false
         return false
       }else{
         this.ishow = false
       }
       this.refreshing = false
       this.loading = false
     }
                    
    },
  },
 async created(){
                console.log(this.list);
   this.onRefresh()
  }
}
</script>

<style lang="scss" scoped>
    .keepAdmin{
            color: #1a1a1a;
            font-weight: 500;
            width: 100%;   
            padding:  0 20px;
            margin-top: 80px;
    }
    .data{
            width: 100%;
            padding: 32px 20px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #f5f6f7;
            color: #333333;
            background-color: #fff;
            font-size: 26px;
            .startTime{
              font-weight: 500;
              width: 200px;
            }
            .weight{
                width: 200px;
                text-align: center;
            }
            .realTlmeMOney{
              font-weight: 500;
              width: 300px;
                text-align: center;
            }
            .state{
              font-weight: 500;
              width: 100px;
                text-align: right;
            }
        }
</style>